import React from 'react';
import CustomModal from '../CustomModal/CustomModal';
import CloseModal from '../Icons/CloseModal';
import TrashIcon from '../Icons/TrashIcon';
import { Conversation, Localization } from '../../shared/interfaces';

interface DeleteConversationModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  localeText?: Localization | undefined
  index: number;
  conversation: Conversation;
  setConfirmDelete: React.Dispatch<React.SetStateAction<number>>;
  onAction: (action: string, conversation: Conversation) => void;
}

const DeleteConversationModal: React.FC<DeleteConversationModalProps> = ({
  modalIsOpen,
  closeModal,
  localeText,
  index,
  conversation,
  setConfirmDelete,
  onAction,
}) => {
  return (
    <CustomModal isOpen={modalIsOpen} closeModal={closeModal}>
      <div className='py-3'>
        <div className='flex justify-center'>
          <TrashIcon
            width={50}
            height={50}
            className='fill-red-500 hover:fill-red-500 bg-active-gray rounded-xl p-2'
          />
        </div>
        <h3 className='text-center text-lg font-semibold'>{localeText?.['Are you sure?']}</h3>
        <p
          className=' text-gray-500 text-[16px]  w-full h-full flex flex-wrap text-center mt-2 items-center justify-center '
          // @ts-expect-error - --delay is a css variable
          style={{ '--delay': index * 0.25 + 's' }}
        >
          {localeText?.['This will remove']} <strong className='ml-1 '>{conversation.title}</strong>
        </p>
        <div className='flex w-full justify-center gap-5 mt-5'>
          <button
            className=' justify-center px-6 py-2 sm:min-w-[150px] text-sm items-center gap-3 transition-all duration-300 dark:text-white cursor-pointer  hover:bg-primary bg-transparent border-2 border-primary text-primary font-bold dark:bg-transparent hover:text-white  whitespace-nowrap relative rounded-primary'
            onClick={(e) => {
              e.stopPropagation();
              setConfirmDelete(-1);
              closeModal();
            }}
          >
            {localeText?.['Cancel']}
          </button>

          <button
            className=' transition-all px-6 py-2 sm:min-w-[150px] text-sm duration-300 dark:text-white cursor-pointer bg-primary hover:bg-black hover:text-white text-white font-bold dark:bg-transparent   relative rounded-primary'
            onClick={(e) => {
              e.stopPropagation();
              onAction('DELETE', conversation);
              closeModal();
            }}
          >
            Confirmar
          </button>
        </div>
        <button className='absolute top-8 right-8' onClick={closeModal}>
          <CloseModal />
        </button>
      </div>
    </CustomModal>
  );
};

export default DeleteConversationModal;
