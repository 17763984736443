import React, { ReactElement } from 'react'
import PlusIcon from '../Icons/PlusIcon'

interface Props {
  createChat: () => void
  text?: string
}

export default function NewChatButton({ createChat, text }: Props): ReactElement {
  const [hover, setHover] = React.useState(false)

  return (
    <a
      className={`w-1/3 flex px-2 min-h-[35px] items-center gap-3 transition-all duration-300 dark:text-white cursor-pointer text-sm hover:bg-primary bg-transparent border-2 border-primary text-primary font-bold h-8 dark:bg-transparent hover:text-white flex-grow overflow-hidden whitespace-nowrap relative rounded-primary`}
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        createChat()
      }}
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      <PlusIcon className={hover ? 'fill-white' : 'fill-primary'} />
      {text || 'New chat'}
    </a>
  )
}
