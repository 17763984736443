import { useCallback, useEffect, useRef } from 'react'
import { createSocketConnection } from '../services/socket.service'
import { Conversation, IMessage } from '../shared/interfaces'

/**
 * Listen to an event on a channel and call the callback function
 * when the event is triggered on the channel (channel.event)
 * @param callBack {function} to be called when the event is triggered
 * @param channel {string} to be joined
 * @param event {string} to be listened
 * @returns void
 */
export function listen(
  callBack: (payload: Conversation | IMessage) => void,
  channel: string,
  event: string,
): () => void {
  window.Echo.join(channel)
    .listen(event, (payload: unknown) => {
      if (event === '.message.sent') {
        const newMessage = {
          ...payload as IMessage,
          isNew: true,
        }
        callBack(newMessage)
      } else {
        callBack(payload as Conversation)
      }
    })
    .subscribed(() => {

    })
    .error((error: unknown) => {
      console.error('error in', channel, error)
    })

  return function cleanUp() {
    window.Echo.leaveChannel(channel)
  }
}

export function cleanUp(channel: string) {
  window.Echo.leaveChannel(channel)
}

/**
 * Hook to listen to events on a channel
 * This creates a socket connection if it doesn't exist yet
 * @returns void
 * @example
 * useSocket()
 */
export const useSocket = (): void => {
  const createRef = useRef<boolean>(false)

  useEffect(() => {
    if (!createRef.current) {
      createConnection()
      createRef.current = true
    }
  }, [])

  const createConnection = useCallback(() => {
    createSocketConnection()
  }, [])
}
