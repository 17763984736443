import { createContext, useState } from 'react'
import { User } from '../../shared/interfaces'

interface UserContextData {
  sessionId: number | undefined
  setSessionId: (sessionId: number | undefined) => void
  setToken: (token: string | undefined) => void
  setUser: (user: User | undefined) => void
  setShowCookiesBar: (_showCookiesBar: boolean) => void
  showCookiesBar: boolean
  token: string | undefined
  user: User | undefined
}

interface UserProviderProps {
  children: React.ReactNode
}

const UserContext = createContext<UserContextData>({} as UserContextData)

export function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [sessionId, setSessionId] = useState<number | undefined>(undefined)
  const [showCookiesBar, setShowCookiesBar] = useState<boolean>(false)
  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, sessionId, setSessionId, showCookiesBar, setShowCookiesBar }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
