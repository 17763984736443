import React, { ReactNode } from 'react';
import Modal from 'react-modal';

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 51
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '300px',
    maxWidth: '600px',
    overflowX: 'hidden'
  },
};

// Props del componente
interface CustomModalProps {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  styles?: Modal.Styles;

}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, closeModal, children, styles }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={styles ?? customStyles}
      appElement={document.getElementById('root') as HTMLElement}
      contentLabel="Modal"
    // shouldCloseOnOverlayClick={false}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
