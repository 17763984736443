import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import i18n from 'i18next'

const NoAuth = (): React.ReactElement => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const lang = searchParams.get('lang') ?? 'es'
    void i18n.changeLanguage(lang)
  }, [])

  return (
    <>
      <div
        className={`flex flex-col gap-6 m-auto w-full h-screen justify-center items-center bg-white bg-opacity-90 fixed`}
      >
        <img src='../assets/images/MIA.png' alt='logo__mia' loading='lazy' className='h-24 mb-[19px]' />
        <p className='text-center text-[20px] leading-3'>
          {t("No tienes permiso para acceder.")}
        </p>
        <p className='text-center text-[20px] leading-3'>
          {t('Prueba a cerrar tu sesión del Cloud y vuelve a entrar.')}
        </p>
      </div>
    </>
  )
}

export default NoAuth
