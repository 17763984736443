import { useTranslation } from 'react-i18next'

interface CookieSettingsProps {
  analyticsConsent: boolean
  setAnalyticsConsent: (consent: boolean) => void
  onSave: () => void
}

const CookieSettings: React.FC<CookieSettingsProps> = ({ analyticsConsent, setAnalyticsConsent, onSave }) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col rounded-md gap-2'>
      <div className='flex w-full h-auto items-center px-4 py-3'>
        <div className='w-10/12 h-auto text-lg font-bold'>{t('config_cookies.title')}</div>
        <div className='flex w-2/12 h-auto justify-end'>
          <button className='cursor-pointer focus:outline-none text-gray-400 hover:text-gray-800' onClick={onSave}>
            <i className='mdi mdi-close-circle-outline text-2xl'></i>
          </button>
        </div>
      </div>
      <div className='flex flex-row w-full items-center bg-gray-100 border-b border-gray-200 px-5 py-3 text-sm gap-4'>
        <div className='flex-1'>
          <p className='font-bold mb-3'>{t('config_cookies.cookies_needed.title')}</p>
          <p>{t('config_cookies.cookies_needed.description')}</p>
        </div>
        <div className='w-40 border-l-1 border-gray-200 text-right mt-3'>{t('always_active')}</div>
      </div>
      <div className='flex w-full items-center bg-gray-100 border-b border-gray-200 px-5 py-3 text-sm'>
        <div className='flex-1'>
          <p className='font-bold mb-3'>{t('config_cookies.cookies_unnecessary.title')}</p>
          <p>{t('config_cookies.cookies_unnecessary.description')}</p>
        </div>
      </div>
      <div className='flex w-full items-center bg-gray-100 border-b border-gray-200 px-5 py-3 text-sm'>
        <div className='flex-1'>
          <p>{t('cookies_analytics')}</p>
        </div>
        <div className='w-40 text-right flex justify-end gap-2'>
          <label className='inline-flex items-center gap-2'>
            Si
            <input
              type='checkbox'
              className='w-5 h-5 border-2 border-gray-400 rounded-md transition-all duration-200 hover:shadow-lg cursor-pointer'
              checked={analyticsConsent}
              onChange={() => setAnalyticsConsent(true)}
            />
          </label>
          <label className='inline-flex items-center gap-2'>
            No
            <input
              type='checkbox'
              className='w-5 h-5 border-2 border-gray-400 rounded-md transition-all duration-200 hover:shadow-lg cursor-pointer'
              checked={!analyticsConsent}
              onChange={() => setAnalyticsConsent(false)}
            />
          </label>
        </div>
      </div>
      <div className='flex w-full py-3 justify-end'>
        <button
          type='button'
          className='w-1/4 h-10 bg-primary text-white px-4 py-2 rounded transition-all ease-in-out hover:bg-black-gray'
          onClick={onSave}
        >
          {t('Guardar')}
        </button>
      </div>
    </div>
  )
}

export default CookieSettings
