import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/fedefarma-logo.png'

const TermsAndConditions = (): React.ReactElement => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  window.scrollTo(0, 0)

  return (
    <div className='flex w-full flex-col p-8 mt-[50px]'>
      <div className='flex flex-col m-auto max-w-[1200px] w-full'>
        <div className='relative w-full h-20'>
          <img src={logo} className='absolute top-0 w-44' alt="fedefarma-logo" />
        </div>
        <Trans
          i18nKey='termsAndConditions'
          components={{
            h1: <p className='font-bold text-lg' />,
            h2: <p className='font-bold text-[16px]' />,
            center: <div className='text-center' />,
            'w-full': <div className='w-full mb-4' />,
            p: <p className='mt-4 font-normal text-[14px]' />,
            b: <b className='text-[14px] font-bold' />,
            bs: <b className='text-[14px] font-bold underline' />,
            ol: (
              <ol className='list-decimal list-inside text-[16px] font-bold mb-1' />
            ),
            'ol-regular': (
              <ol className='list-decimal list-inside text-[14px] font-normal mb-1' />
            ),
            li: <li className='mb-2 ml-8' />,
            'li-flex': <li className='font-bold mt-4 mb-1 flex' />,
            indent: <div className='ml-4' />,
            'p-indent': <p className='font-bold negative-indent' />,
            a: <a className='text-primary underline' target='_blank' />,
            inline: (
              <span
                style={{
                  display: 'inline',
                  whiteSpace: 'pre-wrap',
                  fontWeight: 'normal',
                }}
              />
            ),
            mt: <div className='mt-4' />,
            ml: <div className='ml-8' />,
            '-ml': <div className='-ml-8' />,
            table: <table className='w-full border-2 border-black mt-4' />,
            thead: <thead />,
            tbody: <tbody />,
            th: (
              <th className='p-2 border-2 border-black font-bold text-[16px]' />
            ),
            tr: <tr />,
            td: (
              <td className='p-4 border-2 border-black font-regular text-[14px]' />
            ),
            tdl: (
              <td className='p-4 border-2 border-black text-[14px] font-light' />
            ),
            'td-center': (
              <td className='p-4 border-2 border-black font-bold text-[14px] text-center' />
            ),
            'td-divided': (
              <td className='divide-black font-bold text-[14px] divide-y-2' />
            ),
            p4: <p className='font-bold p-4' />,
            ul: (
              <ul className='list-disc list-inside text-[14px] font-bold mb-1' />
            ),
            section: <div className='mt-10' />,
            privacyLink: (
              <p
                className='inline cursor-pointer underline text-primary'
                onClick={() => {
                  navigate('/privacy-policy')
                }}
              />
            ),
            cookiesLink: (
              <p
                className='inline cursor-pointer underline text-primary'
                onClick={() => {
                  navigate('/cookie-policy')
                }}
              />
            ),
            termsLink: (
              <p
                className='inline cursor-pointer underline text-primary'
                onClick={() => {
                  navigate('/terms-and-conditions')
                }}
              />
            )
          }}
        />
        <div className='flex flex-col items-end w-full'>
          <button
            className="w-32 h-8 bg-primary text-white rounded-lg cursor-pointer transition-all ease-in-out hover:bg-[#333333]"
            type='button'
            onClick={() => {
              navigate('/', { replace: true })
            }}
          >
            {t('Atrás')}
          </button>
        </div>
        <div className='h-[150px]' />
      </div>
    </div>
  )
}

export default TermsAndConditions
