export interface IFederico {
  accessToken?: string // access token for token based authentication
  apiUrl: string // api url
  clientId?: string // client id for secret key based authentication
  enviorment: 'development' | 'production' | 'local' // enviorment
  layout?: 'chat' | 'page' // layout to use
  localeText?: Localization
  secretKey?: string // secret key for secret key based authentication
  style?: React.CSSProperties // style for the container
}

export interface Conversation {
  blocked: boolean
  created_at: string
  id: number
  is_public: boolean
  title: string
  typing: boolean
  updated_at: string
}

export interface IMessage {
  conversation_id: number
  created_at: string
  id: number
  is_last_part: boolean
  message: string
  type: MessageType
  updated_at: string
  rating: null | 'LIKE' | 'DISLIKE'
  isNew?: boolean
  blocked?: boolean
}

export interface ConversationRequest {
  filter: {
    created_at: string
    title: string
    updated_at: string
  }
  order: {
    created_at: string
    title: string
    updated_at: string
  }
  pagination: Pagination
}

export interface MessageRequest {
  filter: {
    created_at: string
    id: number
    trype?: number
    updated_at: string
  }
  order: {
    direction: string
    field: string
  }
  pagination: Pagination
}

export interface ChatResponse {
  items: Conversation[] | IMessage[]
  data: {
    current_page: number
    last_page: number
    total_items: number
  }
}

export interface IAcceptContract {
  success: boolean
  data: User
}

export interface IAxiosError {
  success: boolean
  status: number
  message: string
}

export interface Pagination {
  page: number
  per_page: number
}

export enum MessageType {
  'ANSWER' = 'ANSWER',
  'ERROR' = 'ERROR',
  'QUESTION' = 'QUESTION',
}

export enum ConversationAction {
  'CREATE' = 'CREATE',
  'DELETE' = 'DELETE',
  'MAKE_PUBLIC' = 'MAKE_PUBLIC',
  'READ' = 'READ',
  'UPDATE' = 'UPDATE',
  'SET_CONVERSATION_LIST' = 'SET_CONVERSATION_LIST',
  'SET_CONVERSATION_MESSAGES' = 'SET_CONVERSATION_MESSAGES',
  'SEND_MESSAGE' = 'SEND_MESSAGE',
  'UPDATE_CONVERSATION' = 'UPDATE_CONVERSATION',
  'UPDATE_RATING' = 'UPDATE_RATING',
  'DELETE_CONVERSATION' = 'DELETE_CONVERSATION',
  'UPDATE_MESSAGE' = 'UPDATE_MESSAGE',
  'SHOW_PROMPTS' = 'SHOW_PROMPTS',
  'HIDE_PROMPTS' = 'HIDE_PROMPTS',
  'TYPING' = 'TYPING',
}

export interface ConversationState {
  conversationList: Conversation[]
  conversationMessages: IMessage[]
  selectedConversation: Conversation | undefined
  showPrompts: boolean
}

export enum UserAction {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface Localization {
  'New conversation': string
  'Make public': string
  'How can I help you today?': string
  'Type a message...': string
  'Are you sure?': string
  'Make public description': string
  Cancel: string
  Today: string
  Yesterday: string
  '2 days ago': string
  'Stop generating': string
  sidebarDescription: string
  'Typing...': string
  'This will remove': string
  [key: string]: string
}

export interface User {
  channel?: string
  email: string
  farmacloud_token: string
  id: number
  language: string
  name: string
  first_name: string
  last_name: string
  organization: IUserOrganization | undefined
  shargoEnabled: boolean | undefined
  shargoIncompleteData: string[] | undefined
  cookiesAccepted?: boolean
  contracts_accepted: boolean
}

export interface IUserOrganization {
  code: string
  id: number
  identifier: string
  name: string
  shargoIncompleteData: string[]
  socialReason: string
  token: string
  uuid: string
  address: string
  serviceAcceptedAt: string
  shargoEnabled: boolean
}
