import CustomModal from "../CustomModal/CustomModal"

interface ModalErrorProps {
    message: string
    onClose?: () => void
}


const ModalError = ({ message, onClose }: ModalErrorProps) => {
    return (
        <CustomModal
          isOpen={true}
          closeModal={() => onClose}
        >
          <div id="close-icon" className="absolute right-2 top-2 cursor-pointer" onClick={onClose}>
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#000000">
                <g data-name="Layer 2">
                  <g data-name="close">
                    <rect width="24" height="24" opacity="0"/>
                    <path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.71a1 1 0 0 0-1.42 1.42L10.59 12 5.71 16.88a1 1 0 0 0 1.42 1.42L12 13.41l4.88 4.88a1 1 0 0 0 1.42-1.42L13.41 12l4.88-4.88a1 1 0 0 0 0-1.41z"/>
                  </g>
                </g>
              </svg>
          </div>
          <div className='flex flex-col justify-center items-center gap-2 py-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='40'
              height='40'
              fill='rgba(231, 45, 81, 1)'
            >
              <g data-name='Layer 2'>
                <g data-name='alert-circle'>
                  <rect width='24' height='24' opacity='0' />
                  <path d='M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z' />
                  <circle cx='12' cy='16' r='1' />
                  <path d='M12 7a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z' />
                </g>
              </g>
            </svg>

            <p className='text-base text-center text-[#e72d51]'>{message}</p>
          </div>
        </CustomModal>
    )
}
export default ModalError