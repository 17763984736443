import { ReactElement } from 'react'
import OpenSideBarIcon from '../Icons/OpenSidebar'
import CloseSideBarIcon from '../Icons/CloseSideBar'

interface Props {
  onCloseSidebar: () => void
  showOpenMenu: boolean
  isMobile: boolean
}

export default function OpenSideBarBtn(props: Props): ReactElement {
  const { onCloseSidebar, showOpenMenu, isMobile } = props
  return (
    <span
      onClick={() => {
        onCloseSidebar()
      }}
    >
      <a
        className={`flex items-center ${isMobile && showOpenMenu ? 'justify-end' : 'justify-center'} h-[45px] w-[45px] transition-colors duration-200 cursor-pointer hover:bg-black bg-black-gray rounded-full`}
        style={
          !showOpenMenu
            ? {
              paddingBottom: '0.3rem',
              paddingRight: '0.3rem',
            }
            : {}
        }
      >
        {showOpenMenu ? <OpenSideBarIcon /> : <CloseSideBarIcon />}
      </a>
    </span>
  )
}
