/**
 * A component that displays a sidebar with a list of conversations and options to create, edit, and delete conversations.
 *
 * @param onCloseSidebar - A function to close the sidebar.
 * @param selectedConversation - The ID of the currently selected conversation.
 * @param setSelectedConversation - A function to set the selected conversation.
 * @returns A ReactElement representing the SideBar component.
 */
import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState, type ReactElement } from 'react'
import { Trans } from 'react-i18next'
import miaIcon from '../../assets/images/mini_mia.png'
import { LanguageContext } from '../../hooks/context/LanguageContext'
import useConversation from '../../hooks/useConversation'
import { listen } from '../../hooks/useSocket'
import { Conversation, ConversationAction, IMessage } from '../../shared/interfaces'
import NewChatButton from '../Buttons/NewChat'
import DeleteConversationModal from '../DeleteConversationModal/DeleteConversationModal'
import ConversationIcon from '../Icons/ConversationIcon'
import EditIcon from '../Icons/EditIcon'
import PlusIcon from '../Icons/PlusIcon'
import TrashIcon from '../Icons/TrashIcon'
import { useNavigate } from 'react-router-dom'

interface Props {
  conversationList: Conversation[]
  dispatch: React.Dispatch<{
    type: ConversationAction
    payload: any // eslint-disable-line
  }>
  selectedConversation: Conversation | undefined
  userId: number
  sessionId: number
  showOpenMenu: boolean
  isMobile: boolean
  inputRef: React.MutableRefObject<HTMLTextAreaElement | null>
  setStartMessage: (value: boolean) => void
}

const SideBar = (props: Props): ReactElement => {
  const { localeText } = useContext(LanguageContext)
  const navigate = useNavigate()
  const { selectedConversation, dispatch, conversationList, userId, sessionId, showOpenMenu, inputRef, setStartMessage } = props
  const [ready, setReady] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [confirmDelete, setConfirmDelete] = useState<number>(-1)
  const [newTitle, setNewTitle] = useState<string | undefined>(undefined)
  const [hoverNewBtn, setHoverNewBtn] = useState<boolean>(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const stop = useRef<boolean>(false)
  const { createNewConversation, deleteConversation, loading, updateConversation, getConversationMessages } = useConversation(
    { ready },
    dispatch,
  )

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  // set ready to true when the component is mounted
  useLayoutEffect(() => {
    setReady(true)
  }, [])

  const conversationSaved = useCallback(
    (data: Conversation | IMessage) => {
      dispatch({ type: ConversationAction.UPDATE_CONVERSATION, payload: data })
    },
    [dispatch],
  )

  const conversationDestroyed = useCallback(
    (data: Conversation | IMessage) => {
      dispatch({ type: ConversationAction.DELETE_CONVERSATION, payload: data })
    },
    [dispatch],
  )

  useEffect(() => {
    if (ready) {
      listen(conversationDestroyed, `users.${userId}`, '.conversation.destroyed')
    }
    if (!stop.current && ready) {
      listen(conversationSaved, `users.${userId}`, '.conversation.saved')
      listen(conversationSaved, `user_sessions.${sessionId}`, '.conversation.saved')
      stop.current = true
    }
  }, [conversationSaved, conversationDestroyed, ready])

  const onAction = (action: string, conversation: Conversation, value?: string) => {
    switch (action) {

      case ConversationAction.CREATE:
        setEdit(false)
        createNewConversation(localeText?.['New conversation'])
        break
      case ConversationAction.READ:
        getConversationMessages(conversation.id)
        setStartMessage(conversation.typing)
        // borrar el contenido del input
        inputRef && inputRef.current && (inputRef.current.value = '')
        setEdit(false)
        if (selectedConversation?.id !== conversation.id) {
          dispatch({ type: ConversationAction.READ, payload: conversation })
          dispatch({ type: ConversationAction.HIDE_PROMPTS, payload: {} })
        }
        break

      case ConversationAction.UPDATE:
        setEdit(false)
        dispatch({ type: ConversationAction.UPDATE, payload: conversation })
        updateConversation({
          id: conversation.id,
          title: value,
        } as Conversation)

        break

      case ConversationAction.DELETE:
        fadeOutZoom(conversation.id)
        setTimeout(() => {
          if (confirmDelete === conversation.id) {
            deleteConversation(conversation.id)
            dispatch({ type: ConversationAction.DELETE, payload: conversation.id })
            setEdit(false)
          }
        }, 500)
        break

      case ConversationAction.MAKE_PUBLIC:
        dispatch({ type: ConversationAction.MAKE_PUBLIC, payload: conversation.id })
        updateConversation({
          id: conversation.id,
          is_public: true,
        } as Conversation)
        setEdit(false)
        break

      default:
        setEdit(false)
        break
    }
  }

  const fadeOutZoom = (id: number) => {
    const element = document.getElementById(`conversation_${id}`)
    if (element) {
      element?.style.setProperty('scale', '0')
    }
  }

  const navigateTo = (path: string): void => {
    navigate(path)
  }

  useLayoutEffect(() => {
    if (selectedConversation?.id !== undefined) {
      setNewTitle(undefined)
    }
  }, [selectedConversation])

  return (
    <div
      className={`flex flex-col  h-full   border-r border-gray-100 dark:border-dark-gray/20 bg-light-gray overflow-hidden`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (confirmDelete !== -1) {
          setEdit(false)
        }
      }}
    >
      {!showOpenMenu ? (
        <div className='pl-3  pr-2 mt-2 flex flex-row w-full gap-2'>
          <NewChatButton
            createChat={() => {
              dispatch({ type: ConversationAction.SHOW_PROMPTS, payload: {} })
              onAction(ConversationAction.CREATE, {} as Conversation)
              // limpiar el input
              inputRef && inputRef.current && (inputRef.current.value = '')
            }}
            text={localeText?.['New conversation']}
          />
        </div>
      ) : (
        <div className='mt-2 flex flex-row w-full h-[35px]'>
          <div
            className={`mx-1 hover:bg-primary bg-transparent border-2 border-primary text-primary font-bold p-2 h-full w-full flex justify-center align-center hover:cursor-pointer rounded-primary transition-all duration-300 ${loading ? 'disabled' : ''
              }`}
            onClick={() => {
              onAction(ConversationAction.CREATE, {} as Conversation)
              dispatch({ type: ConversationAction.SHOW_PROMPTS, payload: {} })
              inputRef && inputRef.current && (inputRef.current.value = '')
            }}
            onMouseEnter={() => setHoverNewBtn(true)}
            onMouseLeave={() => setHoverNewBtn(false)}
          >
            <PlusIcon className={!hoverNewBtn ? 'fill-primary' : 'fill-white'} />
          </div>
        </div>
      )}
      {/* Conversations */}
      <div className=' flex flex-col justify-between h-full'>
        <div className='mt-4 md:max-h-[50%] max-h-[65%]  h-full overflow-y-auto'>
          {conversationList.map((conversation, index: number) => {
            return (
              <div
                key={`conversation_${conversation.id}`}
                id={`conversation_${conversation.id}`}
                className={`flex flex-row w-full  items-center  dark:text-white cursor-pointer sm:text-sm text-xs h-12 flex-grow overflow-hidden whitespace-nowrap relative ${selectedConversation?.id === conversation.id
                  ? 'bg-active-gray dark:bg-medium-gray/10 border-l-4 border-l-primary text-black-gray'
                  : 'bg-transparent dark:bg-transparent hover:bg-active-gray dark:hover:bg-secondary/10 text-gray-500'
                  } ${confirmDelete === conversation.id ? '!border-l-0' : ''}`}
                onClick={() => {
                  onAction(ConversationAction.READ, conversation)
                }}
                onMouseEnter={() => {
                  const absoluteGradientDiv = document.getElementById(`absolute-gradient_${index}`)
                  if (absoluteGradientDiv) {
                    absoluteGradientDiv.style.display = 'none'
                  }
                }}
                onMouseLeave={() => {
                  const absoluteGradientDiv = document.getElementById(`absolute-gradient_${index}`)
                  if (absoluteGradientDiv) {
                    absoluteGradientDiv.style.display = 'block'
                  }
                }}
              >
                <>
                  <div className={`w-full flex gap-3 items-center pl-${showOpenMenu ? '5' : '2'} rounded-primary`}>
                    <div className={`flex flex-row gap-2 align-center justify-center`}>
                      <ConversationIcon />
                    </div>

                    {edit && selectedConversation?.id === conversation.id ? (
                      <input
                        className='bg-transparent dark:bg-transparent dark:text-white text-black selection:bg-primary/30 focus:outline-none focus:ring-1 focus:ring-primary/30 focus:bg-transparent'
                        type='text'
                        style={{
                          width: 'calc(100% - 7rem)',
                        }}
                        defaultValue={conversation.title}
                        autoFocus
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.stopPropagation()
                          onAction(ConversationAction.READ, conversation)
                        }}
                        onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setNewTitle(e.target.value)
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation()
                            e.preventDefault()
                            onAction(ConversationAction.UPDATE, conversation, e.currentTarget.value)
                          }
                        }}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          //select all text
                          e.target.select()
                        }}
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                          e.stopPropagation()
                        }}
                      />
                    ) : (
                      !showOpenMenu && (
                        <p
                          className='font-bold overflow-hidden '
                          style={{
                            width: 'calc(100% - 7rem)',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                          title={conversation.title}
                        >
                          {selectedConversation?.id === conversation.id
                            ? newTitle
                              ? newTitle
                              : conversation.title
                            : conversation.title}
                        </p>
                      )
                    )}
                  </div>
                  {selectedConversation?.id === conversation.id && !showOpenMenu && (
                    <div className='absolute inset-y-0 top-0 right-0 h-full'>
                      <div className='flex flex-row justify-end pr-2 pl-3 items-center h-full gap-0.5'>
                        <EditIcon
                          onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.stopPropagation()
                            setEdit(true)
                          }}
                        />
                        <TrashIcon
                          onClick={(e: React.MouseEvent<SVGElement>) => {
                            e.stopPropagation()
                            setConfirmDelete(conversation.id)
                            openModal()
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    id={`absolute-gradient_${index}`}
                    className={`absolute inset-y-0 right-0 ${selectedConversation?.id === conversation.id ? '-translate-x-[55px] xl:w-8 w-4 ' : 'xl:w-8 w-4 '
                      }`}
                  />
                </>
                {conversation.id === confirmDelete && (
                  <DeleteConversationModal
                    modalIsOpen={modalIsOpen}
                    closeModal={closeModal}
                    localeText={localeText}
                    index={index}
                    conversation={conversation}
                    setConfirmDelete={setConfirmDelete}
                    onAction={onAction}
                  />
                )}
              </div>
            )
          })}
        </div>

        <div
          className={`flex absolute bottom-0  flex-col  p-5  left-2 items-center justify-center gap-2 fadeIn `}
          style={{
            transition: 'visibility 0s, opacity 0.8s forwards',
            display: showOpenMenu ? 'none' : 'flex ',
          }}
        >
          <div className='flex flex-row    gap-2 items-center justify-start w-full h-full leading-tight self-end z-10'>
            <div className='md:w-16 w-10'>
              <img src={miaIcon} alt='logo' className='w-full' />
            </div>
            <span className=' text-xs sm:leading-6 md:text-lg font-bold' dangerouslySetInnerHTML={{ __html: localeText?.['My AI Assistant'] ?? '' }} />
          </div>
          <div className={`w-full h-full text-[12px]   duration-200 hidden md:block`}>
            <Trans
              i18nKey={localeText?.['sidebarDescription'] ?? ''}
              components={{
                span: <span className='text-primary text-[12px]  font-bold' />,
              }}
            />
          </div>

          <div className="w-full h-full text-[12px] duration-200 flex flex-col md:flex-row list-none space-y-4 md:space-y-0 md:space-x-4 items-center justify-center text-primary underline">
            <a className="cursor-pointer" onClick={() => navigateTo('/terms-and-conditions')}>{localeText?.['Términos y condiciones']}</a>
            <a className="cursor-pointer" onClick={() => navigateTo('/privacy-policy')}>{localeText?.['Política de privacidad']}</a>
          </div>

        </div>
      </div>
    </div>
  )
}

export default SideBar
