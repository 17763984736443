/**
 * Creates an instance of axios to make HTTP requests and configures it with the provided parameters.
 * @param secretKey - The secret key of the client.
 * @param clientId - The client id.
 * @param apiUrl - The URL of the API.
 */
import axios from 'axios'

export const api = axios.create()

/**
 * Configures the API with the provided secret key, client ID, and API URL.
 * @param secretKey - The secret key to authenticate with the API.
 * @param clientId - The client ID to authenticate with the API.
 * @param apiUrl - The URL of the API to use. Defaults to 'https://fede-chat-api.com/api'.
 */
export const configApi = async (secretKey: string, clientId: string, apiUrl: string) => {
  api.defaults.baseURL = apiUrl || 'https://fede-chat-api.com/api'

  api
    .request({
      method: 'post',
      url: '/oauth/token',
      auth: {
        username: clientId,
        password: secretKey,
      },
    })
    .then((res) => {
      api.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
      ready()
    })
    .catch((err) => {
      console.log(err)
    })
}

/**
 * Configures the API token and base URL for the API requests.
 * @param token - The API token to be used for authorization.
 * @param apiUrl - The base URL of the API. If not provided, a default URL will be used.
 */
export const configTokenApi = async (token: string, apiUrl: string) => {
  api.defaults.baseURL = apiUrl || 'https://fede-chat-api.com/api'
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  localStorage.setItem('token', token)
  ready()
}

/**
 * Indicates that the API is ready to be used.
 */
const ready = () => {
  // dispatch message event to indicate that the API is ready
  const event = new CustomEvent('chat-api-ready', { detail: { data: 'ready' } })
  window.dispatchEvent(event)
}

// on error 500 try back again after 30 seconds, maximum 3 times, incrementing the time by 30 seconds
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error
    const originalRequest = config

    if (error.response?.status === 500 && !originalRequest._retry) {
      originalRequest._retry = true
      const backOff = 30000
      const maxBackOff = 3
      const backOffTime = backOff * Math.pow(2, originalRequest.retryCount)

      if (originalRequest.retryCount < maxBackOff) {
        console.info(`Retrying request in ${backOffTime}ms`)
        setTimeout(() => api(originalRequest), backOffTime)
      }
    }

    //401 redirect to import.meta.env.VITE_FARMACLOUD_SSO_URL
    if (error.response?.status === 401) {
      // window.location.href = import.meta.env.VITE_FARMACLOUD_SSO_URL
    }

    return Promise.reject(error)
  },
)
