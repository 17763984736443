export const ENVIORMENTS = {
  local: {
    SOCKET_KEY: import.meta.env.VITE_SOCKET_KEY || 'federico-dev',
    SOCKET_HOST: import.meta.env.VITE_SOCKET_HOST || 'localhost',
    SOCKET_PORT: 6001,
    SOCKET_CLUSTER: 'mt1',
    SOCKET_SECURE: Boolean(import.meta.env.VITE_SOCKET_SECURE === 'true'),
    SOCKET_ENCRYPTED: Boolean(import.meta.env.VITE_SOCKET_SOCKET_ENCRYPTED === 'true'),
    SOCKET_FORCE_TLS: Boolean(import.meta.env.VITE_SOCKET_SOCKET_FORCE_TLS === 'true'),
    SOCKET_AUTH_ENDPOINT:
      import.meta.env.VITE_SOCKET_SOCKET_AUTH_ENDPOINT || 'http://localhost:8000/api/broadcasting/auth',
  },
  development: {
    SOCKET_KEY: import.meta.env.VITE_SOCKET_KEY || 'federico-dev',
    SOCKET_HOST: import.meta.env.VITE_SOCKET_HOST || 'localhost',
    SOCKET_PORT: 6001,
    SOCKET_CLUSTER: 'mt1',
    SOCKET_SECURE: Boolean(import.meta.env.VITE_SOCKET_SECURE === 'true'),
    SOCKET_ENCRYPTED: Boolean(import.meta.env.VITE_SOCKET_SOCKET_ENCRYPTED === 'true'),
    SOCKET_FORCE_TLS: Boolean(import.meta.env.VITE_SOCKET_SOCKET_FORCE_TLS === 'true'),
    SOCKET_AUTH_ENDPOINT:
      import.meta.env.VITE_SOCKET_SOCKET_AUTH_ENDPOINT || 'http://localhost:8000/api/broadcasting/auth',
  },
  production: {
    SOCKET_KEY: import.meta.env.VITE_SOCKET_KEY || 'federico',
    SOCKET_HOST: import.meta.env.VITE_SOCKET_HOST || 'localhost',
    SOCKET_PORT: 6001,
    SOCKET_CLUSTER: 'mt1',
    SOCKET_SECURE: Boolean(import.meta.env.VITE_SOCKET_SECURE === 'true'),
    SOCKET_ENCRYPTED: Boolean(import.meta.env.VITE_SOCKET_SOCKET_ENCRYPTED === 'true'),
    SOCKET_FORCE_TLS: Boolean(import.meta.env.VITE_SOCKET_SOCKET_FORCE_TLS === 'true'),
    SOCKET_AUTH_ENDPOINT:
      import.meta.env.VITE_SOCKET_SOCKET_AUTH_ENDPOINT || 'http://localhost:8000/api/broadcasting/auth',
  },
}
