import React, { useState } from 'react';

interface Props {
  selected: boolean;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}
export default function ThumbUpIcon({ selected, onClick }: Props) {
  const [animateBounce, setAnimateBounce] = useState<boolean>(false);

  const animate = () => {
    setAnimateBounce(true);
    setTimeout(() => {
      setAnimateBounce(false);
    }, 200);
  }

  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`w-[18px] ${selected ? 'fill-[#cfffb4] stroke-[#58cd15]  hover:fill-[#a6f8c3]' : 'stroke-slate-400 hover:stroke-slate-300'}  ${animateBounce ? 'animate-ping' : ''}  cursor-pointer flex-shrink-0 transform ease-in-out duration-100`}

      height="24"
      width="24"
      onClick={(e) => {
        onClick(e)
        animate()
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
    </svg>
  )
}
