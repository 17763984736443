import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { ENVIORMENTS } from '../config'

declare global {
  interface Window {
    Pusher: typeof Pusher
    Echo: Echo
  }
}

/**
 * Creates a pusher connection if it doesn't exist yet
 * @returns void
 */
export function createPusherConnection(): void {
  if (!(window.Pusher != null)) {
    window.Pusher = Pusher
  }
}

/**
 * Creates a socket connection if it doesn't exist yet
 * @returns void
 */
export function createSocketConnection(): void {
  createPusherConnection()
  const token = localStorage.getItem('token')
  const userEnv = localStorage.getItem('user_env') as keyof typeof ENVIORMENTS

  if (!(window.Echo != null) && userEnv) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: ENVIORMENTS[userEnv].SOCKET_KEY,
      wsHost: ENVIORMENTS[userEnv].SOCKET_HOST,
      wsPort: Number(ENVIORMENTS[userEnv].SOCKET_PORT ?? 6001),
      forceTLS: Boolean(ENVIORMENTS[userEnv].SOCKET_FORCE_TLS === true),
      disableStats: true,
      authEndpoint: ENVIORMENTS[userEnv].SOCKET_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      cluster: 'eu',
    })
  }
}
