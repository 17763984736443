import { useEffect } from 'react'
import fedeLogo from '../../assets/images/fedefarma-logo.png'

interface LoadingOverlayProps {
  overlay?: 'page' | 'content'
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  const { overlay } = props

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  return (
    <div className={`absolute w-full h-full inset-0 bg-white  bg-blend-lighten ${overlay === 'content' ? 'fixed z-[5]' : 'translate-y-[-50px] z-[10]'}`}>
      <div className='flex flex-col items-center justify-center h-full'>
        <img src={fedeLogo} alt='loading' className='w-1/6 animate-pulse' />
      </div>
    </div>
  )
}

export default LoadingOverlay
