import { useTranslation } from 'react-i18next'
import { Federico } from '../../federico'
import { useEffect } from 'react';

const Home = () => {
  const { t } = useTranslation()
  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight(); // Inicializar en montaje

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return (
    <Federico
      layout='page'
      apiUrl={import.meta.env.VITE_API_URL as string}
      style={{
        height: 'calc(100vh - 48px)'
      }}
      enviorment={import.meta.env.VITE_APP_ENV as 'development' | 'production' | 'local'}
      localeText={{
        'virtual_assistant_message': t('Soy tu asistente virtual y puedo cometer errores. No soy farmacéutica.'),
        'New conversation': t('Nueva conversación'),
        'Type a message...': t('Escribe un mensaje'),
        'How can I help you today?': t('¿Cómo puedo ayudarte hoy?'),
        'Are you sure?': t('¿Estás seguro?'),
        'Make public': t('Hacer público'),
        Cancel: t('Cancelar'),
        'Make public description': t('Al hacer público este chat, cualquier persona podrá verlo y responderlo.'),
        Today: t('Hoy'),
        Yesterday: t('Ayer'),
        '2 days ago': t('Hace 2 días'),
        'Stop generating': t('Dejar de generar'),
        sidebarDescription: t(
          '<span>La primera asistente inteligente del sector desarrollada por tu cooperativa para tu farmacia. </span>Con <span>MIA</span> obtendrás todas las respuestas a tus consultas sobre artículos y vademécum de la cooperativa, precios y stock, además de calcular márgenes óptimos para todas tus referencias.',
        ),
        'Typing...': t('Escribiendo...'),
        'This will remove': t('Esto eliminará de forma permanente la conversación'),
        'Prompt 1 title': t('Precio'),
        'Prompt 1 text': t('¿Qué precio tiene el Ozempic?'),
        'Prompt 2 title': t('Stock'),
        'Prompt 2 text': t('¿Tenéis stock de Ciclopirox?'),
        'Prompt 3 title': t('Interacciones'),
        'Prompt 3 text': t('¿El NeoBianacid es apto para mujeres embarazadas?'),
        'Prompt 4 title': t('Margen'),
        'Prompt 4 text': t('¿Qué PVP debo poner en el CN: 208484 para tener un margen del 30%?'),
        'My AI Assistant': t('Mi asistente <br /> de IA'),
        'Like': t('Buena respuesta'),
        'Dislike': t('Mala respuesta'),
        'excceded_messages': t('Has alcanzado el límite de mensajes permitidos por día, por favor inténtalo más tarde.'),
        'mia_error_response': t('He sufrido un error interno, por favor inténtalo más tarde.'),
        'Términos y condiciones': t('Términos y condiciones'),
        'Política de privacidad': t('Política de privacidad'),
        'modal_welcome_title': t('Bienvenido al servicio de MIA'),
        'modal_welcome_text': t('modal_welcome_text'),
        'Accept': t('Aceptar')
      }}
    />
  )
}

export default Home
