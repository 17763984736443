import axios, { type AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { api } from '../api'
import {
  ConversationAction,
  User,
  type ChatResponse,
  type Conversation,
  type ConversationRequest,
  type IAcceptContract,
  type IAxiosError,
  type IMessage
} from '../shared/interfaces'
import useAxios from './useAxios'

/**
 * @brief Custom hook to manage the conversation
 * @param conversationId The id of the conversation
 * @returns The conversation and the actions to manage it
 */

interface Props {
  conversationId?: number | undefined
  ready?: boolean
}

interface MessageResponse {
  success?: boolean
  data?: IMessage
  message?: string
  status?: number
}


const useConversation = (
  { conversationId, ready }: Props = { conversationId: undefined, ready: false },
  dispatch: React.Dispatch<{
    type: ConversationAction
    payload: any // eslint-disable-line
  }> = () => { },
): {
  createNewConversation: (title?: string) => Promise<number>
  deleteConversation: (conversationId: number) => Promise<boolean>
  getConversationMessages: (conversationId: number) => Promise<IMessage[]>
  getConversationList: (params?: ConversationRequest) => Promise<Conversation[]>
  loading: boolean
  sendMessage: (message: string, id: number) => Promise<MessageResponse>
  selectedConversation: number | undefined
  stopGenerating: (conversation: Conversation) => void
  updateConversation: (data: Partial<Conversation>) => void
  updateRating: (data: Partial<IMessage>) => Promise<boolean>
  acceptContracts: () => Promise<IAcceptContract | IAxiosError>
} => {
  const [loading, setLoading] = useState<boolean>(true)
  const [requestCounter, setRequestCounter] = useState(0)
  const { sendRequest } = useAxios(api);

  useEffect(() => {
    if (ready) {
      getConversationList()
    }
  }, [ready])

  const getConversationList = useCallback(async (): Promise<Conversation[]> => {
    const response: AxiosResponse<ChatResponse> = await api.post('/conversations/list', {
      order: {
        field: 'created_at',
        direction: 'desc',
      },
    })

    if (response?.status !== 200) {
      return []
    }

    const {
      data: { items },
    } = response

    dispatch({ type: ConversationAction.SET_CONVERSATION_LIST, payload: items as Conversation[] })

    return items as Conversation[]
  }, [])

  const createNewConversation = async (title?: string) => {
    const cleanTitle = title?.replace(/<\/?[^>]+(>|$)/g, "") ?? 'New Conversation'
    const response: AxiosResponse<Conversation> = await api.post('/conversations', {
      title: cleanTitle,
      is_public: false,
    })

    if (response?.status !== 201) {
      return -1
    }
    const { data } = response

    dispatch({ type: ConversationAction.READ, payload: data })
    // dispatch({ type: ConversationAction.SHOW_PROMPTS, payload: {} })
    getConversationList()

    return data.id
  }

  const deleteConversation = async (conversationId: number): Promise<boolean> => {
    const response: AxiosResponse = await api.delete(`/conversations/${conversationId}`)

    if (response?.status !== 200) {
      return false
    }

    dispatch({ type: ConversationAction.DELETE, payload: conversationId })
    dispatch({ type: ConversationAction.SHOW_PROMPTS, payload: {} })

    return true
  }

  const updateConversation = async (data: Partial<Conversation>) => {
    const dataClean = {
      id: data.id,
      title: data.title?.replace(/<\/?[^>]+(>|$)/g, "")
    }
    const response: AxiosResponse<Conversation> = await api.put(`/conversations/${data.id}`, dataClean)

    if (response?.status !== 200) {
      return false
    }
    dispatch({ type: ConversationAction.UPDATE, payload: response.data as Conversation })
    return true
  }


  const updateRating = async (data: Partial<IMessage>): Promise<boolean> => {
    const response: AxiosResponse<IMessage> = await api.patch(`/conversations/${data.conversation_id}/messages/${data.id}/rating`, {
      rating: data.rating?.toUpperCase() ?? null,
    })

    if (response?.status !== 200) {
      return false
    }

    dispatch({ type: ConversationAction.UPDATE_RATING, payload: response.data as IMessage })
    return true
  }

  const getConversationMessages = async (conversationId: number): Promise<IMessage[]> => {
    const response: AxiosResponse<ChatResponse> = await api.post(`/conversations/${conversationId}/messages/list`, {
      order: {
        field: 'id',
        direction: 'desc',
      },
      pagination: {
        page: 1,
        per_page: 50,
      },
    })

    if (response?.status !== 200) {
      dispatch({ type: ConversationAction.SET_CONVERSATION_MESSAGES, payload: [] })
      return []
    }

    const {
      data: { items },
    } = response

    dispatch({ type: ConversationAction.SET_CONVERSATION_MESSAGES, payload: items.reverse() as IMessage[] })
    if (items.length === 0) {
      dispatch({ type: ConversationAction.SHOW_PROMPTS, payload: {} })
    } else {
      dispatch({ type: ConversationAction.HIDE_PROMPTS, payload: {} })
    }
    return items as IMessage[]
  }


  const sendMessage = async (message: string, id: number): Promise<MessageResponse> => {
    const config = {
      url: `/conversations/${id}/messages`,
      method: 'POST',
      data: { message },
    };
    setLoading(true)
    const { data, error } = await sendRequest(config);

    if (error) {
      return {
        success: false,
        status: 500,
        message: error
      };
    }

    dispatch({ type: ConversationAction.HIDE_PROMPTS, payload: {} })

    return {
      success: true,
      data: data as IMessage,
      message: 'Message sent',
    };
  };

  const acceptContracts = async (): Promise<IAcceptContract | IAxiosError> => {
    try {
      const response = await api.patch(`/auth/contracts`, {
        "terms_and_conditions": true,
        "privacy_policy": true,
        "cookie_policy": true
      });

      return {
        success: true,
        data: response.data as User
      }
    } catch (error: unknown) {
      // Verifica si el error es una instancia de AxiosError
      if (axios.isAxiosError(error)) {
        // Ahora puedes asumir con seguridad que `error` es de tipo AxiosError y acceder a `response`
        return {
          success: false,
          status: error.response?.status ?? 500, // Usa el operador de encadenamiento opcional aquí
          message: error.response?.data?.message ?? 'Unknown error occurred',
        };
      } else {
        return {
          success: false,
          status: 500,
          message: 'Unknown error occurred',
        };
      }
    }
  };


  const stopGenerating = (conversation: Conversation) => {
    api.patch(`/conversations/${conversation.id}/stop-generation`)
    api.interceptors.request.eject(0)
    api.interceptors.response.eject(0)
  }

  const excludedEndpointRegex = /\/conversations\/\d+\/messages\/\d+\/rating/;

  api.interceptors.request.use((config) => {
    const url = config.url?.toLowerCase() ?? '';
    if (!excludedEndpointRegex.test(url)) {
      if (requestCounter === 0) {
        setLoading(true);
      }
      setRequestCounter(prev => prev++);
    }
    return config;
  }, (error) => {
    setRequestCounter(prev => prev--);

    if (requestCounter === 0) {
      setLoading(false);
    }
    return Promise.reject(error);
  });

  api.interceptors.response.use((response) => {
    const url = response.config.url?.toLowerCase() ?? '';
    setRequestCounter(prev => prev--);

    if (!excludedEndpointRegex.test(url)) {
      if (requestCounter === 0) {
        setLoading(false);
      }
    }
    return response;
  }, (error) => {
    setRequestCounter(prev => prev--);
    if (requestCounter === 0) {
      setLoading(false);
    }
    return Promise.reject(error);
  });


  return {
    createNewConversation,
    deleteConversation,
    getConversationMessages,
    getConversationList,
    loading,
    sendMessage,
    selectedConversation: conversationId,
    stopGenerating,
    updateConversation,
    updateRating,
    acceptContracts
  }
}

export default useConversation
