import React, { useState } from 'react';

interface Props {
  selected: boolean;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}
export default function ThumbDownIcon({ selected, onClick }: Props) {
  const [animateBounce, setAnimateBounce] = useState<boolean>(false);

  const animate = () => {
    setAnimateBounce(true);
    setTimeout(() => {
      setAnimateBounce(false);
    }, 200);
  }

  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={2}
      viewBox="0 0 24 24"
      strokeLinecap='round'
      strokeLinejoin='round'
      className={`w-[18px] ${selected ? 'fill-[#f9e1e1] stroke-red-400  hover:fill-red-200' : 'stroke-slate-400 hover:stroke-slate-300'}  ${animateBounce ? 'animate-ping' : ''}  cursor-pointer flex-shrink-0 transform ease-in-out duration-100`}
      height="24"
      width="24"
      onClick={(e) => {
        onClick(e)
        animate()
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
    </svg>
  )
}
