/**
 * Renders a chat or page layout component based on the provided props.
 * @param accessToken - Access token for token based authentication.
 * @param apiUrl - API URL.
 * @param clientId - Client ID for secret key based authentication.
 * @param enviorment - Environment.
 * @param layout - Layout to use. Defaults to 'page'.
 * @param localeText - Localization text.
 * @param secretKey - Secret key for secret key based authentication.
 * @param style - Style for the container.
 * @returns A React element.
 */

import React, { useContext, useEffect, useState } from 'react'
import { configApi, configTokenApi } from '../api'
import PageLayout from '../components/Layout/Layout'
import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay'
import { LanguageContext } from '../hooks/context/LanguageContext'
import UserContext from '../hooks/context/UserContext'
import { useSocket } from '../hooks/useSocket'
import { LOCALES } from '../shared/common'
import type { IFederico } from '../shared/interfaces'
import '../styles/index.css'
import Cookies from 'js-cookie';

function Federico({
  accessToken,
  apiUrl,
  clientId,
  enviorment,
  localeText = LOCALES,
  secretKey,
  style,
}: IFederico): React.ReactElement {
  const { token } = useContext(UserContext)
  localStorage.setItem('user_env', enviorment)
  const [loading, setLoading] = useState<boolean>(false)
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    const existingCookie = Cookies.get('mia_domain');
    if (!existingCookie) {
      // Cookies.set('mia_domain', crypto.randomUUID(), {domain: '.miafarma.ai' });
    }
  }, [])

  // connect to socket
  useSocket()

  // install api
  useEffect(() => {
    setLoading(true)

    const installApi = () => {
      if (secretKey && clientId && apiUrl) {
        configApi(secretKey, clientId, apiUrl)
      }

      if ((!secretKey || !clientId) && accessToken) {
        configTokenApi(accessToken, apiUrl)
      }

      if (token) {
        configTokenApi(token, apiUrl)
      }

      setLoading(false)
    }

    installApi()
  }, [secretKey, clientId, apiUrl, accessToken, token])

  // listen for chat-api-ready event
  useEffect(() => {
    window.addEventListener('chat-api-ready', async (event) => {
      const { detail } = event as CustomEvent
      if (detail.data === 'ready') {
        setReady(true)
      }
    })

    return () => {
      window.removeEventListener('chat-api-ready', () => { })
    }
  }, [])

  return (
    <LanguageContext.Provider value={{ localeText }}>
      {loading && !ready && <LoadingOverlay />}
      <div className='relative ' style={style}>
        <PageLayout />
      </div>
    </LanguageContext.Provider>
  )
}

export default Federico
