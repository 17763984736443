interface props {
  className: string
}

export default function PlusIcon({ className }: props) {
  return (
    <svg
      className={className}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='18'
      height='18'
      viewBox='0 0 24 24'
    >
      <g data-name='Layer 2'>
        <g data-name='plus'>
          <rect width='24' height='24' transform='rotate(180 12 12)' opacity='0' />
          <path d='M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z' />
        </g>
      </g>
    </svg>
  )
}
