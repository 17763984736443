export default function CloseSideBarIcon() {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1872 7.87484C15.4752 7.87484 15.7632 7.98509 15.9826 8.20447C16.4225 8.64434 16.4225 9.35534 15.9826 9.79522L12.2645 13.5133L15.842 17.218C16.2728 17.6657 16.2605 18.3778 15.8138 18.8087C15.3661 19.2396 14.654 19.2272 14.2231 18.7817L9.87834 14.2817C9.45197 13.8396 9.45759 13.1387 9.89184 12.7045L14.3918 8.20447C14.6112 7.98509 14.8992 7.87484 15.1872 7.87484Z'
        fill='white'
      />
      <mask
        id='mask0_7012_5407'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='9'
        y='7'
        width='8'
        height='13'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.1872 7.87484C15.4752 7.87484 15.7632 7.98509 15.9826 8.20447C16.4225 8.64434 16.4225 9.35534 15.9826 9.79522L12.2645 13.5133L15.842 17.218C16.2728 17.6657 16.2605 18.3778 15.8138 18.8087C15.3661 19.2396 14.654 19.2272 14.2231 18.7817L9.87834 14.2817C9.45197 13.8396 9.45759 13.1387 9.89184 12.7045L14.3918 8.20447C14.6112 7.98509 14.8992 7.87484 15.1872 7.87484Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_7012_5407)'>
        <rect x='24' y='24' width='24' height='24' transform='rotate(-180 24 24)' fill='white' />
      </g>
    </svg>
  )
}
