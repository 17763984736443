export default function ConversationIcon() {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='22'
      height='22'
      xmlSpace='preserve'
      viewBox='0 0 26 24'
    >
      <g id='Icon/Outline/message-square'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7 10C7 9.448 7.448 9 8 9C8.552 9 9 9.448 9 10C9 10.552 8.552 11 8 11C7.448 11 7 10.552 7 10ZM12 9C11.448 9 11 9.448 11 10C11 10.552 11.448 11 12 11C12.552 11 13 10.552 13 10C13 9.448 12.552 9 12 9ZM16 9C15.448 9 15 9.448 15 10C15 10.552 15.448 11 16 11C16.552 11 17 10.552 17 10C17 9.448 16.552 9 16 9ZM20 15C20 15.551 19.551 16 19 16H8.554C8.011 16 7.477 16.148 7.01 16.428L4 18.234V5C4 4.449 4.449 4 5 4H19C19.551 4 20 4.449 20 5V15ZM19 2H5C3.346 2 2 3.346 2 5V20C2 20.36 2.194 20.693 2.507 20.87C2.66 20.957 2.83 21 3 21C3.178 21 3.356 20.953 3.515 20.857L8.039 18.143C8.195 18.049 8.373 18 8.554 18H19C20.654 18 22 16.654 22 15V5C22 3.346 20.654 2 19 2Z'
          fill='#333333'
        />
        <mask
          id='mask0_7055_6053'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='2'
          y='2'
          width='20'
          height='19'
        >
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7 10C7 9.448 7.448 9 8 9C8.552 9 9 9.448 9 10C9 10.552 8.552 11 8 11C7.448 11 7 10.552 7 10ZM12 9C11.448 9 11 9.448 11 10C11 10.552 11.448 11 12 11C12.552 11 13 10.552 13 10C13 9.448 12.552 9 12 9ZM16 9C15.448 9 15 9.448 15 10C15 10.552 15.448 11 16 11C16.552 11 17 10.552 17 10C17 9.448 16.552 9 16 9ZM20 15C20 15.551 19.551 16 19 16H8.554C8.011 16 7.477 16.148 7.01 16.428L4 18.234V5C4 4.449 4.449 4 5 4H19C19.551 4 20 4.449 20 5V15ZM19 2H5C3.346 2 2 3.346 2 5V20C2 20.36 2.194 20.693 2.507 20.87C2.66 20.957 2.83 21 3 21C3.178 21 3.356 20.953 3.515 20.857L8.039 18.143C8.195 18.049 8.373 18 8.554 18H19C20.654 18 22 16.654 22 15V5C22 3.346 20.654 2 19 2Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_7055_6053)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' width='24' height='24' fill='#333333' />
          </g>
        </g>
      </g>
    </svg>
  )
}
