import { Fragment, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay'
import TopBarManagement from '../components/TopBarManagement'
import UserContext from '../hooks/context/UserContext'

const ProtectedRoutes = () => {
  const { user } = useContext(UserContext)

  if (user === undefined) {
    window.location.href = import.meta.env.VITE_FARMACLOUD_SSO_URL as string
  }

  return (
    <Fragment>
      <div
        style={{
          height: '48px',
        }}
        className='bg-gray-200 dark:bg-gray-700'
      >
        <TopBarManagement />
      </div>
      {user ? <Outlet /> : <LoadingOverlay />}
    </Fragment>
  )
}

export default ProtectedRoutes
