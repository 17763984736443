import React from 'react'
interface Props {
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export default function EditIcon({ onClick }: Props) {
  return (
    <svg
      className='fill-black-gray dark:fill-slate-300 hover:fill-primary transition-all duration-300'
      height={22}
      width={22}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g data-name='Layer 2'>
        <g data-name='edit'>
          <rect width='24' height='24' opacity='0' />
          <path d='M19.4 7.34L16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71zM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7zM16 10.68L13.32 8l1.95-2L18 8.73z' />
        </g>
      </g>
    </svg>
  )
}
