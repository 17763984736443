import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../hooks/context/UserContext'

const TopBarManagement = (): React.ReactElement => {
  const { user } = useContext(UserContext)
  const [farmacloudBar] = useState(document.getElementById('farmacloud-header-bar'))

  const farmaCloudSSOURL: string = import.meta.env.REACT_APP_FARMACLOUDBAR_DATA_ENVIRONMENT as string

  useEffect(() => {
    if (user?.farmacloud_token !== undefined) {
      // @ts-expect-error - farmacloudBar is a custom element
      farmacloudBar?.setToken({
        token: user.farmacloud_token,
      })
    }
  }, [user])

  useEffect(() => {
    const doLogout = (): void => {
      localStorage.removeItem('token')
      window.location.href = farmaCloudSSOURL
    }
    farmacloudBar?.addEventListener('logged-out', doLogout)
    farmacloudBar?.addEventListener('switch-user', doLogout)
    return () => {
      farmacloudBar?.removeEventListener('logged-out', doLogout)
      farmacloudBar?.removeEventListener('switch-user', doLogout)
    }
  }, [])

  return <div id='TopBarManagement'>{/* TopBarManagement */}</div>
}

export default TopBarManagement
