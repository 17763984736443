import { createContext } from 'react'
import { Localization } from '../../shared/interfaces'

interface CommonContextProps {
  localeText: Localization | undefined
}

const LanguageContext = createContext<CommonContextProps>({} as CommonContextProps)

export { LanguageContext }
