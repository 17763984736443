import axios from 'axios'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingOverlay from '../components/LoadingOverlay/LoadingOverlay'
import UserContext from '../hooks/context/UserContext'
import i18n from '../services/i18n'
import { type User } from '../shared/interfaces'

const AutoLogin = (): React.ReactElement => {
  const { user, setUser, setToken, setSessionId } = useContext(UserContext)
  const stop = useRef<boolean>(false)
  const params = useParams<{ token: string }>()
  const navigate = useNavigate()

  useEffect(() => {
    if (params.token !== undefined && !stop.current) {
      stop.current = true
      me(params.token)
        .then(
          async (response: {
            user: User
            session: {
              token: string
              id: number
            }
          }) => {
            const { user, session } = response
            localStorage.setItem('token', session.token)
            setUser(user as User)
            setToken(session.token)
            setSessionId(session.id)
            i18n.changeLanguage(user.language)
          },
        )
        .catch(() => {
          window.location.href = import.meta.env.VITE_FARMACLOUD_SSO_URL as string
        })
    }
  }, [])

  useEffect(() => {
    if (user !== undefined) {
      navigate('/')
    }
  }, [user])

  const me = useCallback(
    async (
      token: string,
    ): Promise<{
      user: User
      session: {
        token: string
        id: number
      }
    }> => {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    [],
  )

  return (
    <div id='AutoLogin'>
      <LoadingOverlay />
    </div>
  )
}

AutoLogin.displayName = 'AutoLogin'

export default AutoLogin
