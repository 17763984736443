import { useEffect } from 'react'
import { clarity } from 'clarity-js'
import TagManager from '@sooro-io/react-gtm-module'

const useClarity = () => {
  useEffect(() => {
    const cookieName = 'analytics'
    const userConsent = localStorage.getItem(cookieName) === 'true'

    if (import.meta.env.VITE_APP_ENV === 'production') {
      const tagManagerArgs = {
        gtmId: 'GTM-T65DBM6R',
        events: [
          {
            event: 'content_loaded',
            consentAnalytics: userConsent,
            consentPreferences: userConsent,
          },
        ],
      }
      TagManager.initialize(tagManagerArgs)
    } else {
      if (window.location.href.includes('localhost')) return
      if (userConsent) {
        clarity.start({
          projectId: import.meta.env.VITE_CLARITY_ID as string,
          upload: 'https://m.clarity.ms/collect',
          track: true,
          content: true,
        })
      }
    }
  }, [])
}

export default useClarity
