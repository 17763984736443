import { defineConfig } from '@twind/core'
import presetAutoprefix from '@twind/preset-autoprefix'
import presetTailwind from '@twind/preset-tailwind'

export default defineConfig({
  presets: [presetAutoprefix(), presetTailwind()],
  darkMode: 'class',
  theme: {
    extend: {
      fontFamily: {
        primary: ['Aeonik-Regular', 'sans-serif'],
        secondary: ['Aeonik-Light', 'sans-serif'],
        bold: ['Aeonik-Bold', 'sans-serif'],
      },
      colors: {
        primary: '#AF1685',
        secondary: '#751e52',
        tertiary: '#333333',
        quaternary: '#F7E8F3',
        quinary: '#ffffff',
        'soft-gray': '#F0f5f9',
        'medium-gray': '#C9D6DF',
        'dark-gray': '#333333',
        'green-brand-2': '#49802A',
        'selected-primary': '#f7e7f3',
        'active-gray': 'rgba(156, 156, 156, .1)',
        'black-gray': '#333333',
      },
      fontSize: {
        xl: '24px',
        lg: '20px',
        base: '14px',
        sm: '13px',
      },
      borderColor: {
        primary: '#AF1685',
        secondary: '#751e52',
        tertiary: '#333333',
        quaternary: '#F7E8F3',
        quinary: '#ffffff',
        'light-gray': '#FAFAFA',
      },
      backgroundColor: {
        'light-gray': '#FAFAFA',
        'border-gray': '#EEEEF0',
        'soft-gray': '#F0f5f9',
        'medium-gray': '#C9D6DF',
        'dark-gray': '#333333',
        'brand-4': '#F7E8F3',
        'active-gray': 'rgba(156, 156, 156, .1)',
        'black-gray': '#333333',
      },
      borderRadius: {
        primary: '2px',
      },
      keyframes: {
        'slide-in-x': {
          '0%': { opacity: 0, transform: 'translateX(100%)' },
          '100%': { opacity: 1, transform: 'translateX(0)' },
        },
        'slide-in-y': {
          '0%': { opacity: 0, transform: 'translateY(100%)' },
          '100%': { opacity: 1, transform: 'translateY(0)' },
        },
        'fade-out-zoom': {
          '0%': { opacity: 1, transform: 'scale(1)' },
          '100%': { opacity: 0, transform: 'scale(0)' },
        },
        typewriter: {
          to: {
            left: '100%',
          },
        },
        blink: {
          '0%': {
            opacity: '0',
          },
          '0.1%': {
            opacity: '1',
          },
          '50%': {
            opacity: '1',
          },
          '50.1%': {
            opacity: '0',
          },
          '100%': {
            opacity: '0',
          },
        },
      },
      animation: {
        'slide-in-x': 'slide-in-x .25s ease-in-out forwards var(--delay, 0)',
        'slide-in-y': 'slide-in-y .25s ease-in-out forwards var(--delay, 0)',
        'fade-out-zoom': 'fade-out-zoom .25s ease-in-out forwards var(--delay, 0)',
        typewriter: 'typewriter 2s steps(--steps) forwards',
        caret: 'typewriter 2s steps(--step) forwards, blink 1s steps(11) infinite 2s',
      },
    },
  },
  preflight: {
    '@import': [
      'url("./assets/fonts/Aeonik-Bold.otf") format("opentype")',
      'url("./assets/fonts/Aeonik-Regular.otf") format("opentype")',
      'url("./assets/fonts/Aeonik-Light.otf") format("opentype")',
    ],
    '@font-face': [
      {
        fontFamily: 'Aeonik-Bold',
        fontWeight: 'bold',
        src: 'url("./assets/fonts/Aeonik-Bold.otf") format("opentype")',
      },
      {
        fontFamily: 'Aeonik-Regular',
        fontWeight: 'normal',
        src: 'url("./assets/fonts/Aeonik-Regular.otf") format("opentype")',
      },
      {
        fontFamily: 'Aeonik-Light',
        fontWeight: 'light',
        src: 'url("./assets/fonts/Aeonik-Light.otf") format("opentype")',
      },
    ],
    '@layer base': {
      body: {
        fontFamily: 'Aeonik-Regular',
        fontWeight: 'normal',
        fontSize: '13pt',
      },
      h1: {
        fontFamily: 'Aeonik-Regular',
        fontWeight: 'bold',
        fontSize: '48pt',
      },
      h2: {
        fontFamily: 'Aeonik-Regular',
        fontWeight: 'bold',
        fontSize: '30pt',
      },
    },
  },
})
