import { Localization } from './interfaces'

export const LOCALES = {
  'New conversation': 'New conversation',
  'Make public': 'Make public',
  'How can I help you today?': 'How can I help you today?',
  'Type a message...': 'Type a message...',
  'Are you sure?': 'Are you sure?',
  virtual_assistant_message: 'I am your virtual assistant and I can make mistakes. I am not a pharmacist.',
  'Make public description': 'This conversation will be visible to all users.',
  Cancel: 'Cancel',
  Today: 'Today',
  Yesterday: 'Yesterday',
  '2 days ago': '2o days ago',
  'Stop generating': 'Stop generating',
  sidebarDescription: 'This is the sidebar description',
  'Typing...': 'Typing...',
  'This will remove': 'This will remove',
}

const stringDays = {
  0: 'Today',
  1: 'Yesterday',
  2: '2 days ago',
}

/**
 * Formats a given date into a string with the format "DD/MM/YYYY HH:mm".
 * @param date - The date to format.
 * @returns A string with the formatted date.
 */
export function formatDateTime(date: Date | string, localeText: Localization | undefined): string {
  if (date === undefined) date = new Date()
  if (typeof date === 'string') date = new Date(date)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  let finalDate: string = ''

  if (day === new Date().getDate()) {
    finalDate = localeText![stringDays[0] as keyof Localization]
  } else if (day === new Date().getDate() - 1) {
    finalDate = localeText![stringDays[1] as keyof Localization]
  } else if (day === new Date().getDate() - 2) {
    finalDate = localeText![stringDays[2] as keyof Localization]
  } else {
    finalDate = `${day}/${month}/${year}`
  }

  if (minutes < 10) {
    return `${finalDate} ${hours}:0${minutes}`
  } else {
    return `${finalDate} ${hours}:${minutes}`
  }
}

export const typingEffect = (text: string, element: HTMLElement) => {
  const textArray = text.split('')
  let index = 0
  const timer = setInterval(() => {
    if (index < textArray.length) {
      element.innerHTML += textArray[index]
      index++
    } else {
      clearInterval(timer)
    }
  }, 30)
}

// format json text to html
export const formatJsonToHtml = (json: string) => {
  // string between ** is bold
  // string between _ is italic
  // string between `` is code
  // string between ~~ is strike
  // string between [] is link
  // string between {} is image
  // string between () is mention
  // string between # is h1
  // string between ## is h2
  // string between ### is h3
  // string starting with - is list
  // string starting with > is quote
  // string starting with ! is image
  // string starting with number. is list item

  const italicRegex = /_(.*?)_/g
  const codeRegex = /`(.*?)`/g
  const strikeRegex = /~~(.*?)~~/g
  const linkRegex = /\[(.*?)\]\((.*?)\)/g
  const imageRegex = /{(.*?)}/g
  const mentionRegex = /\((.*?)\)/g
  const h1Regex = /#(.*?)#/g
  const h2Regex = /##(.*?)##/g
  const h3Regex = /###(.*?)###/g
  const imageItemRegex = /!(.*?)\n/g
  const newLineRegex = /\n/g
  // format dates like 12-02-2023 to 12 Feb 2023
  const dateRegex = /(\d{2})-(\d{2})-(\d{4})/g

  let html = json

  html = html.replace(italicRegex, '<i>$1</i>')
  html = html.replace(codeRegex, '<code>$1</code>')
  html = html.replace(strikeRegex, '<strike>$1</strike>')
  html = html.replace(linkRegex, '<a href="$2" target="_blank">$1</a>')
  html = html.replace(imageRegex, '<img src="$1" />')
  html = html.replace(mentionRegex, '<span class="mention">$1</span>')

  html = html.replace(h1Regex, '<h1>$1</h1>')
  html = html.replace(h2Regex, '<h2>$1</h2>')
  html = html.replace(h3Regex, '<h3>$1</h3>')
  html = html.replace(imageItemRegex, '<img src="$1" />')
  html = html.replace(newLineRegex, '<br />')
  html = html.replace(dateRegex, (_match: string, p1: string, p2: string, p3: string) => {
    const date = new Date(`${p3}-${p2}-${p1}`)
    return `<span class="date">${new Intl.DateTimeFormat('en-GB', { dateStyle: 'medium' }).format(date)}
      </span>`
  })

  return html.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
}
