export default function OpenSideBarIcon(): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon/Outline/chevron-right'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.5002 17C10.2442 17 9.98825 16.902 9.79325 16.707C9.40225 16.316 9.40225 15.684 9.79325 15.293L13.0982 11.988L9.91825 8.695C9.53525 8.297 9.54625 7.664 9.94325 7.281C10.3413 6.898 10.9742 6.909 11.3572 7.305L15.2193 11.305C15.5983 11.698 15.5933 12.321 15.2073 12.707L11.2072 16.707C11.0122 16.902 10.7563 17 10.5002 17Z'
          fill='#fff'
        />
        <mask
          id='mask0_7055_6039'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='9'
          y='7'
          width='7'
          height='10'
        >
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10.5002 17C10.2442 17 9.98825 16.902 9.79325 16.707C9.40225 16.316 9.40225 15.684 9.79325 15.293L13.0982 11.988L9.91825 8.695C9.53525 8.297 9.54625 7.664 9.94325 7.281C10.3413 6.898 10.9742 6.909 11.3572 7.305L15.2193 11.305C15.5983 11.698 15.5933 12.321 15.2073 12.707L11.2072 16.707C11.0122 16.902 10.7563 17 10.5002 17Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_7055_6039)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' width='24' height='24' />
          </g>
        </g>
      </g>
    </svg>
  )
}
