import React from 'react'

interface Props {
  width?: number
  height?: number
  className?: string
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}
export default function TrashIcon({ width = 20, height = 20, className, onClick }: Props) {
  return (
    <svg
      className={`fill-black-gray dark:fill-slate-300 hover:fill-primary transition-all duration-300
      ${className}`}
      width={width}
      height={height}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g id='Icon/Outline/trash-2'>
        <path
          id='Mask'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16ZM16 16C16 16.55 15.55 17 15 17C14.45 17 14 16.55 14 16V12C14 11.45 14.45 11 15 11C15.55 11 16 11.45 16 12V16ZM18 19C18 19.551 17.552 20 17 20H7C6.448 20 6 19.551 6 19V8H18V19ZM10 4.328C10 4.173 10.214 4 10.5 4H13.5C13.786 4 14 4.173 14 4.328V6H10V4.328ZM21 6H20H16V4.328C16 3.044 14.879 2 13.5 2H10.5C9.121 2 8 3.044 8 4.328V6H4H3C2.45 6 2 6.45 2 7C2 7.55 2.45 8 3 8H4V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V8H21C21.55 8 22 7.55 22 7C22 6.45 21.55 6 21 6Z'
        />
        <mask
          id='mask0_7055_6283'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='2'
          y='2'
          width='20'
          height='20'
        >
          <path
            id='Mask_2'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 16C10 16.55 9.55 17 9 17C8.45 17 8 16.55 8 16V12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12V16ZM16 16C16 16.55 15.55 17 15 17C14.45 17 14 16.55 14 16V12C14 11.45 14.45 11 15 11C15.55 11 16 11.45 16 12V16ZM18 19C18 19.551 17.552 20 17 20H7C6.448 20 6 19.551 6 19V8H18V19ZM10 4.328C10 4.173 10.214 4 10.5 4H13.5C13.786 4 14 4.173 14 4.328V6H10V4.328ZM21 6H20H16V4.328C16 3.044 14.879 2 13.5 2H10.5C9.121 2 8 3.044 8 4.328V6H4H3C2.45 6 2 6.45 2 7C2 7.55 2.45 8 3 8H4V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V8H21C21.55 8 22 7.55 22 7C22 6.45 21.55 6 21 6Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_7055_6283)'>
          <g id='&#240;&#159;&#142;&#168; Color'>
            <rect id='Base' width='24' height='24' />
          </g>
        </g>
      </g>
    </svg>
  )
}
