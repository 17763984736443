import { Conversation, ConversationAction, ConversationState, IMessage } from '../../shared/interfaces'

/**
 * Reducer function for managing conversation state.
 * @param state - The current state of the conversation.
 * @param action - The action to be performed on the conversation state.
 * @returns The new state of the conversation.
 */

const ConversationReducer = (
  state: ConversationState = {
    conversationList: [],
    conversationMessages: [],
    selectedConversation: undefined,
    showPrompts: false,
  },
  action: {
    type: string
    payload: Conversation | Conversation[] | number | any // eslint-disable-line
  },
) => {
  switch (action.type) {

    case ConversationAction.SHOW_PROMPTS:
      return {
        ...state,
        showPrompts: true,
      };

    case ConversationAction.HIDE_PROMPTS:
      return {
        ...state,
        showPrompts: false,
      };
    case ConversationAction.READ:
      return {
        ...state,
        selectedConversation: action.payload,
        conversationMessages: [],
        // showPrompts: false,
      }

    case ConversationAction.UPDATE:
      return {
        ...state,
        selectedConversation: action.payload,
        conversationList: updateTitle(action.payload as Conversation, state.conversationList),
      }


    case ConversationAction.DELETE:
      return {
        ...state,
        conversationList: state.conversationList.filter(
          (conversation: Conversation) => conversation.id !== action.payload,
        ),
        conversationMessages: [],
        selectedConversation: undefined,
        // showPrompts: true,
      }

    case ConversationAction.MAKE_PUBLIC:
      return {
        ...state,
        conversationList: makePublic((action.payload as Conversation).id, state.conversationList),
      }

    case 'SET_CONVERSATION_LIST':
      return {
        ...state,
        conversationList: action.payload,
      }

    case 'SET_CONVERSATION_MESSAGES':
      return {
        ...state,
        conversationMessages: action.payload,
      }

    case 'SEND_MESSAGE':
      return {
        ...state,
        conversationMessages: [...state.conversationMessages, action.payload],
      }

    case 'UPDATE_CONVERSATION':
      return {
        ...state,
        conversationList: updateConversationOrAddNew(action.payload as Conversation, state.conversationList),
        selectedConversation: state.selectedConversation?.id === (action.payload as Conversation).id
          ? action.payload
          : state.selectedConversation,
      }

    case 'DELETE_CONVERSATION':
      return {
        ...state,
        conversationList: state.conversationList.filter(
          (conversation: Conversation) => conversation.id !== (action.payload as Conversation).id,
        ),
        selectedConversation:
          state.selectedConversation?.id === (action.payload as Conversation).id
            ? undefined
            : state.selectedConversation,
      }

    case 'UPDATE_MESSAGE':
      return {
        ...state,
        conversationMessages: state.conversationMessages.map((message: Partial<IMessage>) => {
          if (message.id === action.payload.id) {
            message = action.payload
          }
          return message
        }),
      }

    case ConversationAction.UPDATE_RATING:
      return {
        ...state,
        conversationMessages: updateRating(action.payload as IMessage, state.conversationMessages),
      }

    case ConversationAction.TYPING:
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          typing: action.payload as boolean,
        },
      }


    default:
      return state
  }
}

export default ConversationReducer

/**
 * Updates the title of a conversation in the conversation list.
 * @param payload - The payload containing the new title and the id of the conversation to update.
 * @param conversationList - The current list of conversations.
 * @returns A new conversation list with the updated title.
 */
const updateTitle = (payload: Conversation, conversationList: Conversation[]) => {
  const { title, id } = payload
  const newConversationList = conversationList.map((conversation: Conversation) => {
    if (conversation.id === id) {
      conversation.title = title
    }
    return conversation
  })
  return newConversationList
}

/**
 * Updates the is_public property of a conversation to true.
 * @param id - The id of the conversation to update.
 * @param conversationList - The list of conversations to update.
 * @returns A new conversation list with the updated conversation.
 */
const makePublic = (id: number, conversationList: Conversation[]) => {
  const newConversationList = conversationList.map((conversation: Conversation) => {
    if (conversation.id === id) {
      conversation = {
        ...conversation,
        is_public: true,
      }
    }
    return conversation
  })
  return newConversationList
}

/**
 * Updates an existing conversation in the conversationList or adds a new one if it doesn't exist.
 * @param payload The conversation to update or add.
 * @param conversationList The list of conversations to update or add to.
 * @returns The updated conversationList.
 */
const updateConversationOrAddNew = (payload: Conversation, conversationList: Conversation[]) => {
  const { id, is_public } = payload
  const exists = conversationList.find((conversation: Conversation) => conversation.id === id)
  if (exists) {
    conversationList = conversationList.map((conversation: Conversation) => {
      if (conversation.id === id) {
        conversation = payload
      }
      return conversation
    })
  } else if (is_public) {
    conversationList = [payload, ...conversationList]
  }

  return conversationList
}


const updateRating = (payload: IMessage, conversationMessages: IMessage[]) => {
  const { id, rating } = payload
  const exists = conversationMessages.findIndex((conversation: IMessage) => conversation.id === id)
  if (exists !== -1) {
    conversationMessages[exists].rating = rating
  }

  return conversationMessages
}
