import { useState, useCallback } from 'react';
import { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

type UseAxiosResult<T> = {
  loading: boolean;
  sendRequest: (config: AxiosRequestConfig) => Promise<{ data: T | null, error: string | null }>;
};

function useAxios<T = unknown>(axios: AxiosInstance): UseAxiosResult<T> {
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback(async (config: AxiosRequestConfig): Promise<{ data: T | null, error: string | null }> => {
    setLoading(true);

    try {
      const response = await axios(config);
      return { data: response.data, error: null };
    } catch (err) {
      const axiosError = err as AxiosError;
      let errorMessage = 'Unknown error occurred';
      if (axiosError.response) {
        const responseData = axiosError.response.data as { message?: string };
        errorMessage = responseData.message || 'Something went wrong';
      } else if (axiosError.request) {
        errorMessage = 'No response received from the server';
      } else {
        errorMessage = axiosError.message;
      }
      return { data: null, error: errorMessage };
    } finally {
      setLoading(false);
    }
  }, [axios]);

  return { loading, sendRequest };
}

export default useAxios;
