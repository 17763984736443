import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../hooks/context/UserContext';
import CustomModal from '../CustomModal/CustomModal';
import CookieSettings from '../CookieSettings/CookieSettings';

const CookieBanner: React.FC = () => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [show, setShow] = useState<'hidden' | 'byCookieValue'>('hidden');
  const [analyticsConsent, setAnalyticsConsent] = useState(false);
  const { setShowCookiesBar } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookieName =
    import.meta.env.VITE_APP_ENV === 'production'
      ? 'cookieConsentMIA'
      : 'cookieConsentMIA_DEV';

  useEffect(() => {
    const userConsent = localStorage.getItem(cookieName);
    if (!userConsent) {
      setShow('byCookieValue');
    } else if (userConsent === 'accepted') {
      setAnalyticsConsent(true);
    }
  }, [cookieName]);

  const handleAcceptAll = () => {
    localStorage.setItem(cookieName, 'accepted');
    localStorage.setItem('analytics', 'true');
    setShow('hidden');
    setShowCookiesBar(false);
  };

  const handleRejectAll = () => {
    localStorage.setItem(cookieName, 'rejected');
    localStorage.setItem('analytics', 'false');
    setShow('hidden');
    setShowCookiesBar(false);
  };

  const handleConfigure = () => {
    setIsConfigOpen(true);
  };

  const handleSaveConfiguration = () => {
    if (analyticsConsent) {
      localStorage.setItem('analytics', 'true');
    } else {
      localStorage.setItem('analytics', 'false');
    }
    setIsConfigOpen(false);
    setShowCookiesBar(false);
    setShow('hidden');
  };

  const handleGoPolicy = () => {
    navigate('/cookie-policy');
  };

  return (
    <div className={`fixed z-[50] bottom-0 left-0 right-0 bg-white border-t p-4 shadow-lg ${show === 'hidden' ? 'hidden' : ''}`}>
      <div className="text-center">
        <div>
          <span dangerouslySetInnerHTML={{ __html: t('Este sitio web utiliza cookies propias y de terceros para garantizar el correcto funcionamiento de la web, personalizar el contenido y mejorar nuestros servicios mediante el análisis de tus hábitos de navegación. Puede aceptar o rechazar todas las cookies clicando el botón de “<b>Aceptar todo</b>” o “<b>Rechazar todo</b>”, respectivamente, así como configurar la selección de cookies según sus preferencias clicando las casillas correspondientes y pulsando en el botón “<b>Configurar</b>”. Para más información, puede visitar nuestra') }} />
          {' '}
          <span onClick={handleGoPolicy} className="text-primary underline cursor-pointer">{t('Política de Cookies')}</span>
          {'.'}
        </div>

        <div className="mt-4 flex justify-center space-x-4">
          <button onClick={handleAcceptAll} className="bg-primary text-white px-4 py-2 rounded transition-all ease-in-out hover:bg-black-gray">
            {t('Aceptar todo')}
          </button>
          <button onClick={handleRejectAll} className="bg-primary text-white px-4 py-2 rounded transition-all ease-in-out hover:bg-black-gray">
            {t('Rechazar todo')}
          </button>
          <button onClick={handleConfigure} className="bg-primary text-white px-4 py-2 rounded transition-all ease-in-out hover:bg-black-gray">
            {t('Configurar')}
          </button>
        </div>

        {isConfigOpen && (
          <CustomModal
            isOpen={true}
            closeModal={() => setIsConfigOpen(false)}
          >
            <CookieSettings
              analyticsConsent={analyticsConsent}
              setAnalyticsConsent={setAnalyticsConsent}
              onSave={handleSaveConfiguration}
            />
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default CookieBanner;
