import { useContext } from "react";
import { Trans } from "react-i18next";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../hooks/context/LanguageContext";
import CustomModal from "../CustomModal/CustomModal";
import { IAcceptContract, IAxiosError } from "../../shared/interfaces";
import UserContext from "../../hooks/context/UserContext";

interface ModalErrorProps {
  acceptContracts: () => Promise<IAcceptContract | IAxiosError>
  onClose: () => void
}

const customStyles: Modal.Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 51
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '300px',
    maxWidth: '600px',
    overflowX: 'hidden',
    borderRadius: '60px'
  },
};


const WelcomeModal = ({ acceptContracts, onClose }: ModalErrorProps) => {
  const { localeText } = useContext(LanguageContext)
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const handleAcceptContract = async () => {
    const response = await acceptContracts()
    if (!response.success || !user) {
      return
    }

    setUser({
      ...user,
      contracts_accepted: true
    })

    onClose()
  }

  return (
    <CustomModal
      isOpen={true}
      closeModal={() => onClose}
      styles={customStyles}
    >
      <div className='flex flex-col justify-center items-center gap-2 py-2'>
        <h3 className='text-center text-lg font-semibold'>{localeText?.['modal_welcome_title']}</h3>
        <div className='text-[12px] font-normal pt-6 lg:px-20 mb-2 sm:mb-0' >
          <Trans
            i18nKey='modal_welcome_text'
            components={{
              termsLink: (
                <p
                  className='inline cursor-pointer underline text-primary decoration-1'
                  onClick={() => {
                    navigate('/terms-and-conditions', { replace: true })
                  }}
                />
              ),
              policyLink: (
                <p
                  className='inline cursor-pointer underline text-primary decoration-1'
                  onClick={() => {
                    navigate('/privacy-policy', { replace: true })
                  }}
                />
              ),
              a: <a className='text-primary underline' target='_blank' />
            }}
          />
        </div>
        <button className="w-32 h-8 bg-primary text-white rounded-lg cursor-pointer transition-all ease-in-out hover:bg-[#333333]" onClick={handleAcceptContract}>{localeText?.['Accept']}</button>
      </div>
    </CustomModal>
  )
}
export default WelcomeModal